import React, { Component } from "react"
//classes:
import history from "../../classes/history"

class NotFound extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}
	onGoHome = (e) => {
		if (e) e.stopPropagation();
		history.push(`./main?t=${Date.now()}`);
	}
	render() {
		return (<div className="notFound flex_h flex_c_c">
			<div className="wrapper w320px">
				<label>The page you requested was not found!</label>
				<div className="w100 flex_h flex_c_1 m20_t">
					<button onClick={this.onGoHome} className="btn btnDefault">Go home</button>
				</div>
			</div>
		</div>);
	}
}

export default NotFound;