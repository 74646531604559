import React, { Component } from "react"
import Input from "../input/Input"

//icons:
import Icon from "react-icons-kit";
import { chevronUp } from 'react-icons-kit/fa/chevronUp'
import { chevronDown } from 'react-icons-kit/fa/chevronDown'

//Example:
/*
	<Combo placeholder="Period" options={[
			{ id: "overall", title: "Overall" },
			{ id: "day", title: "Day" },
			{ id: "month", title: "Month" },
		]}
		selected = "month" //optional
		onSelect = { el => console.log(el) } //optional
	/>
*/
class Combo extends Component {
	constructor(props) {
		super(props);
		const selected = !props.selected ? null : (!props.options ? null : props.options.filter(el => el.id === props.selected)[0])
		this.state = {
			selected,
			data: null,
			value: !selected ? "" : selected.title,
			closed: true,
		}
	}
	readProp = (key) => {
		const prop = this.props[key];
		switch (key) {
			case "options":
				if (!prop || !Array.isArray(prop)) return []; else return prop;
			case "placeholder":
				if (!prop) return ""; else return prop;
			default:
				return prop;
		}
	}
	onChange = (e) => {
		this.setState({ value: e.target.value });
	}
	clear = () => {
		this.setState({ value: "", closed: true });
	}
	onToggle = (e) => {
		if (e) e.stopPropagation();
		this.setState({ closed: !this.state.closed })
	}
	onSelect = async (e, el) => {
		if (e) e.stopPropagation();
		await this.setState({ closed: true, selected: el, value: el.title })
		if (typeof this.props.onSelect === "function") this.props.onSelect(el);
	}
	render() {
		return (
			<div style={this.props.style ? this.props.style : {}} className={`combo ${this.props.disabled ? "disabled" : ""} ${this.props.className ? this.props.className : ""}`}>
				<div className="head">
					<Input name="value" onChange={this.onChange} value={this.state.value} placeholder={this.readProp("placeholder")} />
					<button disabled={this.props.disabled} onClick={this.onToggle} className="btn btnDefault">
						<Icon icon={this.state.closed ? chevronDown : chevronUp} />
					</button>
				</div>
				<div className="body">
					<div className={`content ${this.state.closed ? "off" : "on"}`}>
						{this.readProp("options").map((el) => {
							return <button onClick={(e) => this.onSelect(e, el)} key={el.id} className="btn btnDefault item">{el.title}</button>
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default Combo;