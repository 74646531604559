import axios from "axios";
import { storageGet } from "./storage"
export const service = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT,
	timeout: 15000
});

service.interceptors.response.use(response => response.data, error => {
	if (error && error.response && error.response.data) return error.response.data;
	return { error: true }
});

service.setHeaders = o => {
	Object.keys(o).forEach(key => {
		// console.log("header: ".green, key, o[key]);
		service.defaults.headers.common[key] = o[key];
	});
};

export const post = async (methodName, o, cb) => {
	let result;
	service.defaults.headers.common["token"] = storageGet("token"); //provide local token
	await service.post(methodName, o).then(res => result = res).catch(err => result = err);
	if (typeof cb === "function") cb(result);
	return result;
}
