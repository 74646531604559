import React, { Component } from "react";

//classes:
import { getUsers, userSendInvite, userDbs, userDbAssign, userDbUnassign, setRole, dbsAll } from "../../classes/user";

//containers
import TopBar from "../topBar/TopBar";

//components:
import Input from "../../components/input/Input";
import Combo from "../../components/combo/Combo";

class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mail: "",
			users: [],
			user: null,
			db: null,
			user2: null,
			role: null,
			dbsAll: null
		}
	}

	onInputChange = (e) => {
		if (e) e.stopPropagation();
		this.setState({ [e.target.name]: e.target.value });
	}
	onSendInvite = async e => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true })
		userSendInvite({ mail: this.state.mail }, (res) => {
			this.setState({ loading: false });
			console.log(res);
		})
	}
	componentDidMount = () => {
		this.loadUsers()
		this.loadDbs()
	}
	loadUsers = () => {
		getUsers((res) => {
			if (Array.isArray(res)) this.setState({ users: res });
		})
	}
	loadDbs = () => {
		dbsAll((res) => {
			if (typeof res === "object" && !res.error) {
				this.setState({ dbsAll: res });
			}
		})
	}
	renderSendInvite = () => {
		const disabled = this.state.loading;

		return <div className="invites p10_all">
			<label className="m5_b">Send invite</label>
			<div className="send flex_h">
				<Input
					maxLength={500}
					disabled={disabled}
					name={"mail"}
					onChange={this.onInputChange}
					onBlur={this.onInputChange}
					// onEnter={this.onLogin}
					value={this.state["mail"]}
					placeholder="mail"
				/>
				<button onClick={this.onSendInvite} className="btn btnDefault sendInvite m10_l">Send invite</button>
			</div>
		</div>
	}
	onUserChange = async (el) => {
		await this.setState({ user: el.id });
	}
	onUserChange2 = async (el) => {
		await this.setState({ user2: el.id });
	}
	onDbChange = async (el) => {
		await this.setState({ db: el.id });
	}
	onRoleChange = async (el) => {
		await this.setState({ role: el.id });
	}
	onAssign = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true })
		userDbAssign({ mail: this.state.user, db: this.state.db }, (res) => {
			this.loadUsers()
			this.setState({ loading: false })
		})
	}
	onUnassign = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true })
		userDbUnassign({ mail: this.state.user, db: this.state.db }, (res) => {
			this.loadUsers()
			this.setState({ loading: false })
		})
	}
	onSetRole = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true })
		setRole({ mail: this.state.user2, role: this.state.role }, (res) => {
			this.loadUsers()
			this.setState({ loading: false })
		})
	}
	renderDbAssign = () => {
		const users = this.state.users.map(el => { return { id: el.mail, title: el.mail } });

		const dbs = (this.state.dbsAll ? this.state.dbsAll : userDbs()).map(el => { return { id: el, title: el } });

		return <div className="dbAssign p10_all ">
			<label className="m5_b">Assign DB</label>
			<div className="flex_h w100 flex_c_b flex_wrap">
				<div className="flex_h ">
					<Combo className="m10_r" placeholder="Choose user" onSelect={this.onUserChange} selected={this.state.user} options={users} />
					<Combo placeholder="Choose DB" onSelect={this.onDbChange} selected={this.state.db} options={dbs} />
				</div>
				<div className="flex_h">
					<button disabled={this.state.loading} onClick={this.onAssign} className="btn btnDefault m10_r">ADD</button>
					<button disabled={this.state.loading} onClick={this.onUnassign} className="btn btnDefault">REMOVE</button>
				</div>
			</div>
		</div >
	}
	renderSetRole = () => {
		const users = this.state.users.map(el => { return { id: el.mail, title: el.mail } });
		const roles = ["user", "admin"].map(el => { return { id: el, title: el } });

		return <div className="dbAssign p10_all">
			<label className="m5_b">Set role</label>
			<div className="flex_h w100 flex_c_b flex_wrap">
				<div className="flex_h ">
					<Combo className="m10_r" placeholder="Choose user" onSelect={this.onUserChange2} selected={this.state.user2} options={users} />
					<Combo placeholder="Choose role" onSelect={this.onRoleChange} selected={this.state.role} options={roles} />
				</div>
				<button disabled={this.state.loading} onClick={this.onSetRole} className="btn btnDefault m10_r">SET</button>
			</div>
		</div >
	}
	renderUsers = () => {
		return <div className="usersList p10_all">
			<label className="m5_b">Users</label>
			{this.state.users.map((u, i) => {
				if (!Array.isArray(u.dbs)) u.dbs = [];
				return <div key={`user_${i}`} className="flex_h flex_wrap entry">
					<div className={`p10_all td_role role_${u.role ? u.role : "user"}`}>
						<label>Role:</label>
						{u.role ? u.role : "user"}
					</div>
					<div className="p10_all td_active">
						<label>Status:</label>
						{u.active ? <b className="green1">Active</b> : <b className="red1">Pending</b>}
					</div>
					<div className="p10_all td_mail">
						<label>Mail:</label>
						{u.mail}
					</div>
					<div className="p10_all td_dbs">
						<label>Databases: </label>
						{u.dbs.map((el, i) => <b key={`db_${i}`}>{`${el}, `}</b>)}
					</div>
				</div>
			})}

		</div >
	}
	render() {
		return (
			<div className="users">
				<TopBar />
				{this.renderSendInvite()}
				{this.renderSetRole()}
				{this.renderDbAssign()}
				{this.renderUsers()}

			</div>
		);
	}
}

export default Users;