import React, { Component } from "react";
//classes:
// import history from "../../classes/history";
import { statsGeneral } from "../../classes/stats";
import { userDbs } from "../../classes/user";
import { sortObject } from "../../classes/utills";

//components:
// import Input from "../../components/input/Input"
import Combo from "../../components/combo/Combo";

//containers:
import TopBar from "../../containers/topBar/TopBar";

//other:
// import Decimal from "decimal.js";
//icons:
import Icon from "react-icons-kit";
// import { chevronUp as up } from 'react-icons-kit/fa/chevronUp'
// import { chevronDown as down } from 'react-icons-kit/fa/chevronDown'
import { sortAlphaAsc as up } from 'react-icons-kit/fa/sortAlphaAsc'
import { sortAlphaDesc as down } from 'react-icons-kit/fa/sortAlphaDesc'
import { minus } from 'react-icons-kit/fa/minus'
import { refresh } from 'react-icons-kit/fa/refresh'
import { usd } from 'react-icons-kit/fa/usd'
import { bitcoin as btc } from 'react-icons-kit/fa/bitcoin'
import { angleRight as right } from 'react-icons-kit/fa/angleRight'
import { angleLeft as left } from 'react-icons-kit/fa/angleLeft'

class Stats extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			general: [],
			sort: {},
			period: "day",
			games: null,
			gamesO: null,
			totals: null,
			game: "all",
			currencies: null,
			currency: "all",
			usd: false,
			page: 0,
			db: null
		}
		this.titles = {
			"_id.day": "Day", //2019-04-03
			"_id.month": "Month", //2019-04
			"_id.house_edge": "HE%",
			"_id.charge_rate": "Charge%",
			"_id.currency": "Currency",
			"_id.game_uuid": "Game",
			"bet_count": "BetCount",
			"bets": "Bets",
			"wins": "Wins"
		}
	}
	componentDidMount = () => {

	}
	getStatsGeneral = async () => {
		await this.setState({ loading: true });
		statsGeneral({ db: this.state.db, page: this.state.page, period: this.state.period, sort: this.state.sort, game_uuid: this.state.game, currency: this.state.currency }, (res) => {
			console.log(res);
			if (!res.error && res.data && Array.isArray(res.data)) {
				const games = [{ id: "all", title: "All games" }]
				for (let game in res.games) games.push({ id: game, title: game });
				const currencies = [{ id: "all", title: "All Cur." }]
				for (let c of res.currencies) currencies.push({ id: c, title: c });

				this.setState({ loading: false, general: res.data, gamesO: { ...res.games, games: "games" }, games, currencies, totals: res.totals ? sortObject(res.totals) : res.totals })
			} else this.setState({ loading: false })
		})
	}
	onReload = e => {
		if (e) e.stopPropagation();
		this.getStatsGeneral();
	}
	onUsd = e => {
		if (e) e.stopPropagation();
		this.setState({ usd: !this.state.usd });
	}
	onSort = async (e, key) => {
		if (e) e.stopPropagation();
		const sort = Object.assign({}, this.state.sort);
		if (!sort[key])
			sort[key] = 1;
		else if (sort[key] === 1)
			sort[key] = -1;
		else delete sort[key];
		await this.setState({ sort });
		this.getStatsGeneral();
	}
	renderSortButton = (key) => {
		return <button className="btn btnDefault" disabled={this.state.loading} onClick={(e) => this.onSort(e, key)}>
			<Icon icon={this.state.sort[key] === 1 ? up : (this.state.sort[key] === -1 ? down : minus)} />
		</button>
	}
	removeSortKey = async (key) => {
		await this.setState({ loading: true });
		const sort = Object.assign({}, this.state.sort);
		delete sort[key];
		await this.setState({ sort });
		this.getStatsGeneral();
	}
	renderSortBy = () => {
		let arr = []
		let i = 0;
		for (let key in this.state.sort) {
			i++
			arr.push(<button onClick={() => this.removeSortKey(key)} key={key} disabled={this.state.loading} className="btn btnDefault">
				<label>{`${i}. ${this.titles[key]}`}</label>
				<Icon icon={this.state.sort[key] === 1 ? up : (this.state.sort[key] === -1 ? down : minus)} />
			</button>)
		}
		return arr;
	}
	onPeriodChange = async (el) => {
		await this.setState({ period: el.id, general: [], sort: {} })
		this.getStatsGeneral();
	}
	onGameChange = async (el) => {
		await this.setState({ game: el.id, general: [] })
		this.getStatsGeneral();
	}
	onCurrencyChange = async (el) => {
		await this.setState({ currency: el.id, general: [] })
		this.getStatsGeneral();
	}
	goPage = async (e, page) => {
		if (e) e.stopPropagation();
		await this.setState({ page });
		this.onReload();
	}
	onDbChange = async (el) => {
		await this.setState({ db: el.id, general: [], sort: {} })
		this.getStatsGeneral();
	}
	renderDb = () => {
		const dbs = userDbs();
		const options = dbs.map(el => { return { id: el, title: el } });
		return <div className="dbs p5_all">
			<Combo style={{ width: "300px" }} placeholder="Choose DB" onSelect={this.onDbChange} selected={this.state.db} options={options} />
		</div>
	}
	render() {
		return (
			<div className="statsWrapper">
				<TopBar />
				{this.renderDb()}
				{this.state.db &&
					<div className="stats">
						<div className="filters">
							<button title={"Reload page"} disabled={this.state.loading} onClick={this.onReload} className="btn btnDefault reload">
								<Icon icon={refresh} />
							</button>
							<button title={"Reload page"} disabled={this.state.loading} onClick={this.onUsd} className="btn btnDefault coin">
								<Icon icon={this.state.usd ? usd : btc} />
							</button>
							<Combo placeholder="Period" selected={this.state.period} options={[
								{ id: "overall", title: "Overall" },
								{ id: "day", title: "Day" },
								{ id: "month", title: "Month" },
							]}
								onSelect={this.onPeriodChange}
							/>
							{this.state.games && <Combo placeholder="Game" onSelect={this.onGameChange} selected={this.state.game} options={this.state.games} />}
							{this.state.currencies && <Combo placeholder="Currency" onSelect={this.onCurrencyChange} selected={this.state.currency} options={this.state.currencies} />}
						</div>
						{
							this.state.loading ?
								<div className="loader flex_h flex_cc p_all50 w100">
									<h3>Please, wait...</h3>
								</div> :
								<div className="content">
									<table className="tbGeneral">
										<tbody>
											<tr className="title" key={-2}>
												{this.state.period !== "overall" && <td className="td_day">Day</td>}
												<td className="td_game">Game</td>
												<td className="td_cur">Cur</td>
												<td className="td_wager">Wager</td>
												<td className="td_wins">Wins</td>
												<td title="bets - wins" className="td_profit">Profit</td>
												<td title="wins / bets * 100 (%)" className="td_rtp">RTP%</td>
												<td className="td_he">HE%</td>
												<td title="100% - rtp - he (%)" className="td_variance">Variance%</td>
												<td className="td_betcount">BetCount</td>
												<td className="td_avgbets">AvgBets</td>
												<td className="td_chargerate">Charge%</td>
												<td title="(bets - wins) * charge rate" className="td_pandorarev">Pandora rev.</td>
											</tr>
											<tr className="sortBy">
												<td colSpan={13 - (this.state.period === "overall" ? 1 : 0)}>
													<label>Sort by:</label>
													{this.renderSortBy()}
												</td>
											</tr>
											<tr className="sort" key={-1}>
												{this.state.period !== "overall" && <td className="td_day">{this.renderSortButton(`_id.${this.state.period}`)}</td>}
												<td className="td_game">{this.renderSortButton("_id.game_uuid")}</td>
												<td className="td_cur">{this.renderSortButton("_id.currency")}</td>
												<td className="td_wager">{this.renderSortButton("bets")}</td>
												<td className="td_wins">{this.renderSortButton("wins")}</td>
												<td className="td_profit"></td>
												<td className="td_rtp"></td>
												<td className="td_he">{this.renderSortButton("_id.house_edge")}</td>
												<td className="td_variance"></td>
												<td className="td_betcount">{this.renderSortButton("bet_count")}</td>
												<td className="td_avgbets"></td>
												<td className="td_chargerate">{this.renderSortButton("_id.charge_rate")}</td>
												<td className="td_pandorarev"></td>
											</tr>

											{this.state.totals && this.state.gamesO && Object.keys(this.state.totals).map((key, i) => {
												const keys = Object.keys(this.state.gamesO);
												const all = []
												for (let game_uuid of keys) {
													if (!this.state.totals[key][game_uuid]) continue;
													all.push(<tr className={`totals ${game_uuid}`} key={`${key}_${game_uuid}`}>
														{this.state.period !== "overall" && <td className="td_day">{`t.${key}`}</td>}
														<td className="td_game">{game_uuid}</td>
														<td colSpan={1}></td>
														<td className="td_wager">{this.state.totals[key][game_uuid]["bets_usd"].toFixed(2)}</td>
														<td colSpan={1}></td>
														<td className={`td_profit ${this.state.totals[key][game_uuid]["profit_usd"] < 0 ? "red" : "green"}`}>{this.state.totals[key][game_uuid]["profit_usd"].toFixed(2)}</td>
														{/* <td className="td_cur">{el.currency}</td>
													<td className="td_wager">{el[`bets${this.state.usd ? "_usd" : ""}`].toFixed(this.state.usd ? 2 : 8)}</td>
													<td className="td_wins">{el[`wins${this.state.usd ? "_usd" : ""}`].toFixed(this.state.usd ? 2 : 8)}</td>
													
													<td className="td_rtp">{el.rtp.toFixed(2)}</td>
													<td className="td_he">{el.house_edge.toFixed(2)}</td>
													<td className="td_variance">{el.variance.toFixed(2)}</td>
													<td className="td_betcount">{el.bet_count}</td>
													<td className="td_avgbets">{el[`avgBets${this.state.usd ? "_usd" : ""}`].toFixed(this.state.usd ? 2 : 8)}</td>
													<td className="td_chargerate">{el.charge_rate.toFixed(2)}</td> */}
														<td colSpan={7} className={`td_pandorarev ${this.state.totals[key][game_uuid]["profit_usd"] < 0 ? "red" : "green"}`}>{this.state.totals[key][game_uuid]["pandoraRev_usd"].toFixed(2)}</td>
													</tr>)
												}
												return all;
											}
											)}

											{
												this.state.general.map((el, i) => {
													return <tr key={i}>
														{this.state.period !== "overall" && <td className="td_day">{el[this.state.period].replaceAll("-", "/")}</td>}
														<td className="td_game">{el.game_uuid}</td>
														<td className="td_cur">{el.currency}</td>
														<td className="td_wager">{el[`bets${this.state.usd ? "_usd" : ""}`].toFixed(this.state.usd ? 2 : 8)}</td>
														<td className="td_wins">{el[`wins${this.state.usd ? "_usd" : ""}`].toFixed(this.state.usd ? 2 : 8)}</td>
														<td className={`td_profit ${el.profit < 0 ? "red" : "green"}`}>{el[`profit${this.state.usd ? "_usd" : ""}`].toFixed(this.state.usd ? 2 : 8)}</td>
														<td className="td_rtp">{el.rtp.toFixed(2)}</td>
														<td className="td_he">{el.house_edge.toFixed(2)}</td>
														<td className="td_variance">{el.variance.toFixed(2)}</td>
														<td className="td_betcount">{el.bet_count}</td>
														<td className="td_avgbets">{el[`avgBets${this.state.usd ? "_usd" : ""}`].toFixed(this.state.usd ? 2 : 8)}</td>
														<td className="td_chargerate">{el.charge_rate.toFixed(2)}</td>
														<td className={`td_pandorarev ${el.pandoraRev < 0 ? "red" : "green"}`}>{el[`pandoraRev${this.state.usd ? "_usd" : ""}`].toFixed(this.state.usd ? 2 : 8)}</td>
													</tr>
												})
											}
										</tbody>
									</table>
								</div>

						}
						<div className="pages">
							<button onClick={(e) => this.goPage(e, this.state.page - 1)} className="btn btnDefault" disabled={this.state.loading || this.state.page === 0}>
								<Icon icon={left} />
							</button>
							<div className="curPage"><label >{this.state.page + 1}</label></div>
							<button onClick={(e) => this.goPage(e, this.state.page + 1)} className="btn btnDefault" disabled={this.state.loading || this.state.general.length === 0}>
								<Icon icon={right} />
							</button>
						</div>
					</div>
				}
			</div>
		);
	}
}

export default Stats;