import React, { Component } from "react";
//classes:
import { userActivate } from "../../classes/user"
import history from "../../classes/history";
import { checkPassStrength } from "../../classes/utills"

//components:
import Input from "../../components/input/Input"
//other:
// import Decimal from "decimal.js";
//icons:
// import Icon from "react-icons-kit";


class Activate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			password1: "",
			password2: "",
		}
		this.strength = { level: 0, title: "" }
	}
	onInputChange = (e) => {
		if (e.target.name === "password1") {
			this.strength = checkPassStrength(e.target.value)
		}
		this.setState({ [e.target.name]: e.target.value });

	}
	onActivate = async (e) => {
		await this.setState({ loading: true });
		userActivate({ password1: this.state.password1, password2: this.state.password2, uuid: this.props.uuid }, r => {
			console.log(r);
			this.setState({ loading: false }, () => {
				if (r.success) history.push("/main");
			});
		})
	}
	renderLogin() {
		const disabled = this.state.loading;
		return <div className="login flex_v flex_c_c">
			<div className="wrapper w320px">
				<h3 className="yellow1 m10_b">
					{this.props.mode === "recover" ? "Recover your account by providing new password below" : "To activate account, please set password!"}
				</h3>
				<div className="m10_b">
					<label className="m10_b">Password</label>
					<Input
						type="password"
						maxLength={20}
						disabled={disabled}
						name={"password1"}
						onChange={this.onInputChange}
						onBlur={this.onInputChange}
						onEnter={this.onActivate}
						value={this.state["password1"]}
						placeholder="password"
					/>
				</div>
				<div className="m10_b">
					{this.strength.title}
				</div>
				<div className="m10_b">
					<label className="m10_b">Repeat Password</label>
					<Input
						type="password"
						maxLength={20}
						disabled={disabled}
						name={"password2"}
						onChange={this.onInputChange}
						onBlur={this.onInputChange}
						onEnter={this.onActivate}
						value={this.state["password2"]}
						placeholder="repeat password" />
				</div>
				<div className="m10_b">
					<button disabled={disabled} onClick={this.onActivate} className="btnDefault w100" >Submit</button>
				</div>
			</div>
		</div>
	}
	render() {
		return (
			this.renderLogin()
		);
	}
}

export default Activate;