import React, { Component } from "react";

//other:
import { Link } from "react-router-dom"
import Recaptcha from "react-google-recaptcha"

//classes:
import { userLogin, userRecover } from "../../classes/user"
import history from "../../classes/history";

//components:
import Input from "../../components/input/Input"

//other:
// import Decimal from "decimal.js";
//icons:
// import Icon from "react-icons-kit";


class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			mail: "",
			password: "",
		}
		this.recaptcha = null
	}
	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}

	onLogin = async (e) => {
		if (e) e.stopPropagation();
		this.setState({ loading: true });
		this.recaptcha.reset();
		this.recaptcha.execute();
	}
	onLogin2 = async (recaptcha) => {
		userLogin({ mail: this.state.mail, password: this.state.password, recaptcha }, res => {
			this.setState({ loading: false }, () => {
				if (res.success) history.push("/main");
			});
		})
	}

	onRecover = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true });
		userRecover({ mail: this.state.mail }, res => {
			this.setState({ loading: false }, () => {
				console.log("recover: ", res);
			});
		})
	}
	goBack = (e) => {
		if (e) e.stopPropagation();
		history.push("/login");
	}

	renderLogin() {
		const disabled = this.state.loading;
		return <div className="login flex_v flex_c_c">
			<Recaptcha
				ref={(el) => this.recaptcha = el}
				size="invisible"
				sitekey="6Lfet8EUAAAAADDKIS8EK-N5p7sTFwkAUjbrhuPL"
				onChange={this.onLogin2}
				badge='inline'
				theme='dark'
			/>
			<div className="wrapper w320px">
				<h3 className="white1 m10_b">{this.props.mode === "login" ? "Login" : "Forget password"}</h3>
				<div className="m10_b">
					<label className="m10_b">Email</label>
					<Input
						maxLength={100}
						disabled={disabled}
						name={"mail"}
						onChange={this.onInputChange}
						onBlur={this.onInputChange}
						onEnter={this.onLogin}
						value={this.state["mail"]}
						placeholder="mail"
					/>
				</div>
				<div className={`m10_b ${this.props.mode === "login" ? "block" : "none"}`} >
					<label className="m10_b">Password</label>
					<Input
						type="password"
						maxLength={100}
						disabled={disabled}
						name={"password"}
						onChange={this.onInputChange}
						onBlur={this.onInputChange}
						onEnter={this.onLogin}
						value={this.state["password"]}
						placeholder="password" />
				</div>
				<div className="m10_b">
					<button disabled={disabled} onClick={this.props.mode === "login" ? this.onLogin : this.onRecover} className="btnDefault w100" >Submit</button>
				</div>
				{this.props.mode === "login" ?
					<Link to="/forget">Forget password?</Link> :
					<div className="m10_b">
						<button disabled={disabled} onClick={this.goBack} className="btnDefault w100" >Go back</button>
					</div>
				}
			</div>
		</div>
	}
	render() {
		return (
			this.renderLogin()
		);
	}
}

export default Login;