import React, { Component } from "react";
import pretty from "js-object-pretty-print";
//classes:
// import history from "../../classes/history";
import { statsTransactions } from "../../classes/stats";
import { userDbs } from "../../classes/user";

//components:
import Input from "../../components/input/Input";
import Combo from "../../components/combo/Combo";

//containers:
import TopBar from "../../containers/topBar/TopBar";

//other:
// import Decimal from "decimal.js";
//icons:
import Icon from "react-icons-kit";
// import { chevronUp as up } from 'react-icons-kit/fa/chevronUp'
// import { chevronDown as down } from 'react-icons-kit/fa/chevronDown'
import { sortAlphaAsc as up } from "react-icons-kit/fa/sortAlphaAsc";
import { sortAlphaDesc as down } from "react-icons-kit/fa/sortAlphaDesc";
import { minus } from "react-icons-kit/fa/minus";
import { refresh } from "react-icons-kit/fa/refresh";
import { usd } from "react-icons-kit/fa/usd";
import { bitcoin as btc } from "react-icons-kit/fa/bitcoin";
import { angleRight as right } from "react-icons-kit/fa/angleRight";
import { angleLeft as left } from "react-icons-kit/fa/angleLeft";
import { chevronRight } from "react-icons-kit/fa/chevronRight";
import { chevronDown } from "react-icons-kit/fa/chevronDown";
import { eye } from "react-icons-kit/fa/eye";
import { eyeSlash } from "react-icons-kit/fa/eyeSlash";

class StatsTr extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			general: [],
			sort: {},
			period: "day",
			games: null,
			game: "all",
			currencies: null,
			currency: "all",
			usd: false,
			page: 0,
			player_id: "",
			session: "",
			active: null,
			showBets: false,
			db: null,
		};
		this.titles = {
			d: "Day",
			action: "Action",
			amount: "Amount",
			bet_transaction_id: "BetTrId",
			currency: "Currency",
			game_uuid: "Game",
			player_id: "Player",
			session_id: "Session",
			type: "Type",
			status: "Status", //"pen", "fin" or "err"
			balance: "Balance", //integrator response.balance
			client_salt: "CS",
			server_salt: "SS",
			nonce: "Nonce", //number for random generation
			config: "Config", //random generation configuration
			steps: "Steps", //player steps during game (Array)
			choice: "Choice", //player choice before start game
		};
	}
	componentDidMount = () => {};

	goToVerify = (data) => {
		const urlBody = `https://codepen.io/pandoragames/pen/${data.url}`;
		const cfg = data.config ? `&config=${btoa(data.config)}` : "";
		return `${urlBody}?server_seed=${btoa(data.server_salt)}&client_seed=${btoa(data.client_salt)}&nonce=${btoa(data.nonce)}${cfg}`;
	};

	onShowBets = (e) => {
		this.setState({ showBets: !this.state.showBets });
	};
	statsTransactions = async () => {
		await this.setState({ loading: true });
		const o = {
			db: this.state.db,
			page: this.state.page,
			sort: this.state.sort,
			game_uuid: this.state.game,
			currency: this.state.currency,
		};
		if (this.state.player_id.trim() !== "") o.player_id = this.state.player_id;
		if (this.state.session.trim() !== "") o.session_id = this.state.session;
		statsTransactions(o, (res) => {
			console.log(res);
			if (!res.error && res.data && Array.isArray(res.data)) {
				const games = [{ id: "all", title: "All games" }];
				for (let game in res.games) games.push({ id: game, title: game });
				const currencies = [{ id: "all", title: "All Cur." }];
				for (let c of res.currencies) currencies.push({ id: c, title: c });

				this.setState({ loading: false, general: res.data, games, currencies });
			} else this.setState({ loading: false });
		});
	};
	onChange = async (e) => {
		await this.setState({ [e.target.name]: e.target.value });
	};
	onReload = (e) => {
		if (e) e.stopPropagation();
		this.statsTransactions();
	};
	onUsd = (e) => {
		if (e) e.stopPropagation();
		this.setState({ usd: !this.state.usd });
	};
	onSort = async (e, key) => {
		if (e) e.stopPropagation();
		const sort = Object.assign({}, this.state.sort);
		if (!sort[key]) sort[key] = 1;
		else if (sort[key] === 1) sort[key] = -1;
		else delete sort[key];
		await this.setState({ sort });
		this.statsTransactions();
	};
	renderSortButton = (key) => {
		return (
			<button className="btn btnDefault" disabled={this.state.loading} onClick={(e) => this.onSort(e, key)}>
				<Icon icon={this.state.sort[key] === 1 ? up : this.state.sort[key] === -1 ? down : minus} />
			</button>
		);
	};
	removeSortKey = async (key) => {
		await this.setState({ loading: true });
		const sort = Object.assign({}, this.state.sort);
		delete sort[key];
		await this.setState({ sort });
		this.statsTransactions();
	};
	renderSortBy = () => {
		let arr = [];
		let i = 0;
		for (let key in this.state.sort) {
			i++;
			arr.push(
				<button onClick={() => this.removeSortKey(key)} key={key} disabled={this.state.loading} className="btn btnDefault">
					<label>{`${i}. ${this.titles[key]}`}</label>
					<Icon icon={this.state.sort[key] === 1 ? up : this.state.sort[key] === -1 ? down : minus} />
				</button>
			);
		}
		return arr;
	};
	onGameChange = async (el) => {
		await this.setState({ game: el.id, general: [] });
		this.statsTransactions();
	};
	onCurrencyChange = async (el) => {
		await this.setState({ currency: el.id, general: [] });
		this.statsTransactions();
	};
	goPage = async (e, page) => {
		if (e) e.stopPropagation();
		await this.setState({ page });
		this.onReload();
	};
	show = async (e, el) => {
		if (e) e.stopPropagation();
		await this.setState({
			active: this.state.active === el._id ? null : el._id,
		});
	};
	onDbChange = async (el) => {
		await this.setState({ db: el.id, general: [], sort: {} });
		this.statsTransactions();
	};
	renderDb = () => {
		const dbs = userDbs();
		const options = dbs.map((el) => {
			return { id: el, title: el };
		});
		return (
			<div className="dbs p5_all">
				<Combo style={{ width: "300px" }} placeholder="Choose DB" onSelect={this.onDbChange} selected={this.state.db} options={options} />
			</div>
		);
	};
	render() {
		return (
			<div className="statsTrWrapper">
				<TopBar />
				{this.renderDb()}
				{this.state.db && (
					<div className="statsTr">
						<div className="filters">
							<button title={"Reload page"} disabled={this.state.loading} onClick={this.onReload} className="btn btnDefault reload">
								<Icon icon={refresh} />
							</button>
							<button title={"Reload page"} disabled={this.state.loading} onClick={this.onUsd} className="btn btnDefault coin">
								<Icon icon={this.state.usd ? usd : btc} />
							</button>
							<button title={"Toggle bets"} disabled={this.state.loading} onClick={this.onShowBets} className="btn btnDefault toggleBets">
								<Icon icon={this.state.showBets ? eye : eyeSlash} />
							</button>

							<Input className="inpFilter" name="session" value={this.state.session} onChange={this.onChange} placeholder="Session" />
							<Input className="inpFilter" name="player_id" value={this.state.player_id} onChange={this.onChange} placeholder="PlayerId" />
							{this.state.games && (
								<Combo placeholder="Game" onSelect={this.onGameChange} selected={this.state.game} options={this.state.games} />
							)}
							{this.state.currencies && (
								<Combo placeholder="Currency" onSelect={this.onCurrencyChange} selected={this.state.currency} options={this.state.currencies} />
							)}
						</div>
						{this.state.loading ? (
							<div className="loader flex_h flex_cc p_all50 w100">
								<h3>Please, wait...</h3>
							</div>
						) : (
							<div className="content">
								<table className="tbGeneral">
									<tbody>
										<tr className="title" key={-2}>
											<td className="td_view">View</td>
											<td className="td_day">Day</td>
											<td className="td_game">Game</td>
											<td className="td_cur">Cur</td>
											<td className="td_player">PlayerId</td>
											<td className="td_bet_amount">BetAmount</td>
											<td className="td_win_amount">GrossWin</td>
											<td className="td_delta">Delta</td>
											<td className="td_nonce">Nonce</td>
											<td className="td_bet_balance">Bal.Before</td>
											<td className="td_win_balance">Bal.After</td>
											<td className="td_win_action">Action</td>
											{/* <td className="td_bet">BetAmount</td>
											<td className="td_amount">Amount</td>
											<td className="td_delta">Delta</td>
											<td className="td_balance">Balance</td>
											<td className="td_status">Status</td>
											<td className="td_action">Action</td>
											<td className="td_type">Type</td>
											<td className="td_nonce">Nonce</td> */}
										</tr>
										{/* <tr className="sortBy">
										<td colSpan={12}>
											<label>Sort by:</label>
											{this.renderSortBy()}
										</td>
									</tr> */}
										{/* <tr className="sort" key={-1}>
										<td className="td_day">{this.renderSortButton(`_id.${this.state.period}`)}</td>
										<td className="td_game">{this.renderSortButton("_id.game_uuid")}</td>
										<td className="td_cur">{this.renderSortButton("_id.currency")}</td>
										<td className="td_wager">{this.renderSortButton("bets")}</td>
										<td className="td_wins">{this.renderSortButton("wins")}</td>
										<td className="td_profit"></td>
										<td className="td_rtp"></td>
										<td className="td_he">{this.renderSortButton("_id.house_edge")}</td>
										<td className="td_variance"></td>
										<td className="td_betcount">{this.renderSortButton("bet_count")}</td>
										<td className="td_avgbets"></td>
										<td className="td_chargerate">{this.renderSortButton("_id.charge_rate")}</td>
										<td className="td_pandorarev"></td>
									</tr> */}
										{
											//filter(this.state.showBets ? (el) => el : el => el.type !== "bet")
											this.state.general
												.filter((el) => typeof el.d !== "undefined")
												.map((el, i) => {
													const rounding = this.state.usd ? 2 : 8;
													const usd = this.state.usd ? "_usd" : "";
													let bet_amount = "---";
													let win_amount = "---";
													let bet_balance = "---";
													let win_balance = "---";
													let delta = "---";
													// let delta;
													// if (isNaN(el.win_balance) || el.win_balance === null) el.win_balance = 0;
													// if (isNaN(el.win_balance_usd) || el.win_balance_usd === null) el.win_balance_usd = 0;
													bet_amount = el[`bet_amount${usd}`].toFixed(rounding);
													if (typeof el.win_amount !== "undefined") win_amount = el[`win_amount${usd}`].toFixed(rounding);
													bet_balance = (el[`bet_balance${usd}`] + el[`bet_amount${usd}`]).toFixed(rounding);

													if (typeof el.win_balance !== "undefined") win_balance = el[`win_balance${usd}`].toFixed(rounding);
													if (typeof el.win_amount !== "undefined") delta = (el[`win_amount${usd}`] - el[`bet_amount${usd}`]).toFixed(rounding);
													// delta = this.state.usd ? (el.win_amount_usd - el.bet_amount_usd).toFixed(2) : (el.win_amount - el.bet_amount).toFixed(8)
													// const win_balance = this.state.usd ? el.win_balance_usd.toFixed(2) : (el.win_balance * 1).toFixed(8)
													const won = el.action === "win" && el.win_amount === 0 ? "lose" : el.action;
													return [
														<tr className="row" key={el._id}>
															<td className="td_view">
																<button onClick={(e) => this.show(e, el)} className="btn btnDefault">
																	<Icon icon={this.state.active === el._id ? chevronDown : chevronRight} />
																</button>
															</td>
															<td className="td_day">{`${el.d.substr(0, 10).replaceAll("-", "/")} ${el.d.substr(11, 8)}`}</td>
															<td className="td_game">{el.game_uuid}</td>
															<td className="td_cur">{el.currency}</td>
															<td className="td_player">{el.player_id}</td>
															<td className="td_bet_amount">{el.bet_amount === 0 ? "0" : bet_amount}</td>
															<td className="td_win_amount">{el.win_amount === 0 ? "0" : win_amount}</td>
															<td className={`td_delta ${delta === "---" ? "" : parseFloat(delta) > 0 ? "green" : "red"}`}>{delta}</td>
															<td className="td_nonce">{el.nonce}</td>
															<td className="td_bet_balance">{el.bet_balance === 0 ? "0" : bet_balance}</td>
															<td className="td_win_balance">{el.win_balance === 0 ? "0" : win_balance}</td>
															<td className={`td_action ${won === "lose" ? "red" : won === "win" ? "green" : ""}`}>{won}</td>
															{/* <td className={`td_bet ${el.win_amount > 0 ? "green" : "red"}`}>{el.bet_amount ? bet_amount : ""}</td>
														<td className={`td_amount ${el.type === "win" ? (el.win_amount > 0 ? "green" : "red") : ""}`}>{el.win_amount > 0 ? win_amount : 0}</td>
														<td className={`td_delta ${el.win_amount > 0 ? "green" : "red"}`}>{el.bet_amount ? delta : ""}</td>
														<td className="td_balance">{win_balance}</td>
														<td className="td_status">{el.status}</td>
														<td className="td_action">{el.action}</td>
														<td className="td_type">{el.type}</td>
														<td className="td_nonce">{el.nonce}</td> */}
														</tr>,
														this.state.active === el._id ? (
															<tr className="details" key={`sub_${el._id}`}>
																<td className="td_view"></td>
																<td colSpan={12}>
																	<div
																		className="flex_v w100 flex_0_0"
																		style={{ textAlign: "left" }}
																		dangerouslySetInnerHTML={{
																			__html: `${pretty.pretty(el, 2, "HTML", false)}${el.url?`<a target="blank" href='${this.goToVerify(el)}'>VERIFY</a>`:""}`,
																		}}
																	></div>
																</td>
															</tr>
														) : null,
													];
												})
										}
									</tbody>
								</table>
							</div>
						)}
						<div className="pages">
							<button
								onClick={(e) => this.goPage(e, this.state.page - 1)}
								className="btn btnDefault"
								disabled={this.state.loading || this.state.page === 0}
							>
								<Icon icon={left} />
							</button>
							<div className="curPage">
								<label>{this.state.page + 1}</label>
							</div>
							<button
								onClick={(e) => this.goPage(e, this.state.page + 1)}
								className="btn btnDefault"
								disabled={this.state.loading || this.state.general.length === 0}
							>
								<Icon icon={right} />
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default StatsTr;
