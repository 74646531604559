import React from "react";
export const delay = (ms, response = undefined) => new Promise(resolve => setTimeout(() => resolve(response), ms));

export const getDayFromTimestamp = unix_timestamp => {
	const d = new Date(unix_timestamp);
	return `${d.getFullYear()}-${(d.getMonth() + 1 < 10 ? "0" : "")}${(d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" : "")}${d.getDate()}`;
};

const prependZero = n => (n * 1 < 10 ? `0${n}` : `${n}`);
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const getTimeFromTimestamp = (unix_timestamp, options) => {
	const date = new Date(unix_timestamp);
	const d = prependZero(date.getDate());
	const h = prependZero(date.getHours());
	const m = prependZero(date.getMinutes());
	const s = `00${date.getSeconds()}`.slice(-2);
	if (options && options.str) {
		return `${d},${h}:${m}${options && options.s ? `:${s}` : ""}`;
	}
	return (
		<>
			<u className="m5_r">{d}</u><b>{months[date.getMonth()]}</b>,<i>{`${h}:${m}${options && options.s ? `:${s}` : ""}`}</i>
		</>
	);
};

export const sortObject = obj => {
	return Object.keys(obj)
		.sort()
		.reduce((a, v) => {
			a[v] = obj[v];
			return a;
		}, {});
};

function scorePassword(pass) {
	let score = 0;
	if (!pass) return score;
	// award every unique letter until 5 repetitions
	let letters = {};
	for (let i = 0; i < pass.length; i++) {
		letters[pass[i]] = (letters[pass[i]] || 0) + 1;
		score += 5.0 / letters[pass[i]];
	}
	// bonus points for mixing it up
	const variations = {
		digits: /\d/.test(pass),
		lower: /[a-z]/.test(pass),
		upper: /[A-Z]/.test(pass),
		nonWords: /\W/.test(pass),
	}
	let variationCount = 0;
	for (let check in variations) variationCount += (variations[check] === true) ? 1 : 0;
	score += (variationCount - 1) * 10;
	return parseInt(score);
}
export const checkPassStrength = (pass) => {
	const score = scorePassword(pass);
	if (score > 80)
		return { level: 3, title: <b className="green1">strong</b> };
	if (score > 60)
		return { level: 2, title: <b className="yellow1">good</b> };
	if (score <= 60)
		return { level: 1, title: <b className="red1">weak</b> };
	return { level: 0, title: "" };
}
export const isValidMail = (mail) => {
	const regexMail = RegExp(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/);
	return regexMail.test(mail);
}

export const toErrorObject = (o) => {
	if (typeof o !== "object") return { error: true, message: "unexpected_error" };
	if (o.error && !o.message) o.message = "unexpected_error";
	return o;
}

/*eslint no-extend-native: ["error", { "exceptions": ["String","Date"] }]*/
String.prototype.replaceAll = function (search, replacement) {
	return this.replace(new RegExp(search, 'g'), replacement);
};

Date.prototype.addHours = function (h) {
	this.setTime(this.getTime() + (h * 60 * 60 * 1000));
	return this;
}

Date.prototype.addDays = function (d) {
	// this.setDate(this.getDate() + d * 1); incorrect way
	this.setTime(this.getTime() + (d * 24 * 60 * 60 * 1000));
	return this;
}

Date.prototype.floor = function() {
	this.setHours(0);
	this.setMinutes(0);
	this.setSeconds(0);
	return this;
};

Date.prototype.ceil = function() {
	this.setHours(23);
	this.setMinutes(59);
	this.setSeconds(59);
	return this;
};