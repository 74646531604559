import React, { Component } from "react";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";

//other
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

//containers:
import Activate from "./containers/activate/Activate";
import Login from "./containers/login/Login";
import Main from "./containers/main/Main";
import Settings from "./containers/settings/Settings";
import Users from "./containers/users/Users";
import NotFound from "./containers/notFound/NotFound";
import Password from "./containers/password/Password";
import Stats from "./containers/stats/Stats";
import StatsTr from "./containers/statsTr/StatsTr";
import Contest from "./containers/contest/Contest";
import Command from "./containers/command/Command";
//classes:
import { userIsLogged, getInfo } from "./classes/user";
import history from "./classes/history";
import { Router } from "react-router-dom";

const VERSION = "1.0.2";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		console.log(process.env, process.env.REACT_APP_API_ENDPOINT);
		console.log(`VERSION ${VERSION}`);
		if (!userIsLogged() && !window.location.pathname.startsWith("/activate/") && !window.location.pathname.startsWith("/recover/"))
			history.push("./login");
		else {
			const ready = () => {
				this.setState({ r: Math.random() });
			};
			getInfo(ready);
		}
	}

	render() {
		return (
			<Router history={history}>
				<ToastContainer />
				<div className="App">
					<Switch>
						<Route path="/login" render={() => (userIsLogged() ? <Redirect to="/main" /> : <Login mode="login" />)}></Route>
						<Route path="/forget" render={() => (userIsLogged() ? <Redirect to="/main" /> : <Login mode="forget" />)}></Route>
						<Route exact path="/" render={(props) => <Redirect to="/main" />} />
						<Route exact path="/main" render={(props) => <Main />} />
						<Route path="/settings" render={(props) => <Settings />} />
						<Route path="/contest" render={(props) => <Contest />} />
						<Route exact path="/users" render={(props) => <Users />} />
						<Route path="/activate/:uuid" render={(o) => <Activate uuid={o.match.params.uuid} />} />
						<Route path="/password" render={() => <Password />} />
						<Route path="/command" render={() => <Command />} />
						<Route
							path="/recover/:uuid"
							render={(o) => (userIsLogged() ? <Redirect to="/main" /> : <Activate mode="recover" uuid={o.match.params.uuid} />)}
						/>
						<Route path="/stats" render={(props) => <Stats />} />
						<Route path="/transactions" render={(props) => <StatsTr />} />

						<Route render={() => <NotFound />} />
					</Switch>
				</div>
			</Router>
		);
	}
}

export default App;
