import React, { Component } from "react"

//classes:
import history from "../../classes/history"

//containers
import TopBar from "../topBar/TopBar"

//components:
// import Input from "../../components/input/Input"

//icons:
import { Icon } from "react-icons-kit"
import { lock } from 'react-icons-kit/fa/lock'

class Settings extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}
	onPassMgrClick = (e) => {
		if (e) e.stopPropagation();
		history.push("/password")
	}
	onCommandsMgrClick = (e) => {
		if (e) e.stopPropagation();
		history.push("/command")
	}
	render() {
		return (<div className="settings">
			<TopBar />
			<div className="flex_h flex_wrap w100 content">
				<button onClick={this.onPassMgrClick} className="btn btnDefault">
					<label>Password management</label>
					<Icon icon={lock} size={25} />
				</button>
				{/* <button className="btn btnDefault">
					<label>2fa management</label>
					<Icon icon={lock} size={25} />
				</button> */}
				<button onClick={this.onCommandsMgrClick} className="btn btnDefault">
					<label>Admin commands</label>
					<Icon icon={lock} size={25} />
				</button>
			</div>
		</div>);
	}
}

export default Settings;