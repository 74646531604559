import React, { Component } from "react";
//classes:
import { userPasswordChange } from "../../classes/user"
import history from "../../classes/history";
import { checkPassStrength } from "../../classes/utills"

//components:
import Input from "../../components/input/Input"

//containers:
import TopBar from "../../containers/topBar/TopBar"

//other:
// import Decimal from "decimal.js";
//icons:
// import Icon from "react-icons-kit";


class Password extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			passwordOld: "",
			password1: "",
			password2: "",
		}
		this.strength = { level: 0, title: "" }
	}
	onInputChange = (e) => {
		if (e.target.name === "password1") {
			this.strength = checkPassStrength(e.target.value)
		}
		this.setState({ [e.target.name]: e.target.value });

	}
	onChangePassword = async (e) => {
		await this.setState({ loading: true });
		userPasswordChange({ passwordOld: this.state.passwordOld, password1: this.state.password1, password2: this.state.password2 }, res => {
			console.log(res);
			this.setState({ loading: false }, () => {
				if (res.success) history.push("/main");
			});
		})
	}
	renderPassword() {
		const disabled = this.state.loading;
		return <div>
			<TopBar />
			<div className="password flex_v flex_c_c">

				<div className="wrapper w320px">
					<h3 className="yellow1 m10_b">
						Change password
				</h3>
					<div className="m10_b">
						<label className="m10_b">Old password</label>
						<Input
							type="password"
							maxLength={20}
							disabled={disabled}
							name={"passwordOld"}
							onChange={this.onInputChange}
							onBlur={this.onInputChange}
							onEnter={this.onActivate}
							value={this.state["passwordOld"]}
							placeholder="old password"
						/>
					</div>
					<div className="m10_b">
						<label className="m10_b">New password</label>
						<Input
							type="password"
							maxLength={20}
							disabled={disabled}
							name={"password1"}
							onChange={this.onInputChange}
							onBlur={this.onInputChange}
							onEnter={this.onChangePassword}
							value={this.state["password1"]}
							placeholder="password"
						/>
					</div>
					<div className="m10_b">
						{this.strength.title}
					</div>
					<div className="m10_b">
						<label className="m10_b">Repeat new password</label>
						<Input
							type="password"
							maxLength={20}
							disabled={disabled}
							name={"password2"}
							onChange={this.onInputChange}
							onBlur={this.onInputChange}
							onEnter={this.onChangePassword}
							value={this.state["password2"]}
							placeholder="repeat password" />
					</div>
					<div className="m10_b">
						<button disabled={disabled} onClick={this.onChangePassword} className="btnDefault w100" >Submit</button>
					</div>
				</div>
			</div>
		</div>
	}
	render() {
		return (
			this.renderPassword()
		);
	}
}

export default Password;