import React, { Component } from "react";

//classes:
import { userDbs, getGames, getCurrencies } from "../../classes/user";
import Toast from "../../classes/toast";
import { contestCreate, contestList, contestRemove } from "../../classes/contest";
import { getTimeFromTimestamp } from "../../classes/utills";

//components:
import Combo from "../../components/combo/Combo";

//containers:
import TopBar from "../topBar/TopBar";
import Input from "../../components/input/Input";

//icons:
import { Icon } from "react-icons-kit";
import { trophy } from "react-icons-kit/icomoon/trophy";
import { plus } from "react-icons-kit/icomoon/plus";

//other:
import DateTimePicker from "react-datetime-picker";

class ContestCreate extends Component {
	constructor(props) {
		super(props);
		this.initialState = {
			games: null, //games that came from server
			name: "", //contest name
			description: "", //contest description
			time_from: new Date().addHours(1), //contest start datetime
			time_to: new Date().addDays(1).addHours(1), //contest end datetime
			amount: "0", //prize pool (total)
			currenciesSelected: null, //array of currencies we selected
			currency: null, //contest currency
			game: null, //dropdown selection
			gamesSelected: null, //array of games we selected
			split: null,
			img0: "",
			img1: "",
			img2: "",
			url: "",
			loading: false,
		};
		this.state = { ...this.initialState };
		this.gameComboRef = React.createRef();
		this.currencyComboRef = React.createRef();
		this.splitComboRef = React.createRef();
	}
	onClear = async () => {
		await this.setState({ ...this.initialState });
		this.splitComboRef.current.clear();
	};
	componentDidMount = () => {
		this.setState({ loading: true });
		getGames({}, (games) => {
			if (typeof games === "object" && !games.error) this.setState({ games, loading: false });
			this.setState({ loading: false });
		});
	};
	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	onFromChange = async (time_from) => {
		if (this.state.time_to.getTime() < time_from.getTime()) {
			const time_to = new Date(new Date().setTime(time_from.getTime() + 24 * 60 * 60 * 1000));
			await this.setState({ time_to, time_from });
		} else this.setState({ time_from });
	};
	onToChange = (time_to) => {
		this.setState({ time_to });
	};
	onCurrencyChange = (el) => {
		this.setState({ currency: el.id });
	};
	onSplitChange = (el) => {
		this.setState({ split: el.id });
	};
	onGameChange = (el) => {
		this.setState({ game: el.id });
	};
	onGameAdd = async () => {
		if (this.state.game === null) return;
		let arr = [];
		if (this.state.game === "all") {
			for (let game in this.state.games) if (arr.indexOf(game) === -1) arr.push(game);
		} else {
			if (Array.isArray(this.state.gamesSelected) && this.state.gamesSelected.indexOf(this.state.game) === -1)
				arr = [...this.state.gamesSelected, this.state.game];
			else arr = [this.state.game];
		}
		await this.setState({ gamesSelected: arr, game: null });
		this.gameComboRef.current.clear(); //clear selection
	};
	onGameRemove = (e, game) => {
		if (e) e.stopPropagation();
		if (this.state.gamesSelected === null) return;
		let arr = this.state.gamesSelected.filter((el) => el !== game);
		if (arr.length === 0) arr = null;
		this.setState({ gamesSelected: arr });
	};
	renderGamesSelected = () => {
		const disabled = this.state.loading || this.props.disabled;
		if (!this.state.gamesSelected) return <label>Please, select at least 1 game</label>;
		return this.state.gamesSelected.map((game) => (
			<button key={game} onClick={(e) => this.onGameRemove(e, game)} className="btn btnDefault m5_all" disabled={disabled}>
				{game}
			</button>
		));
	};
	//------------------------------------------------------
	onCurrencyAdd = async () => {
		if (this.state.currency === null || this.props.currencies === null) return;
		let arr = [];
		if (this.state.currency === "all") {
			for (let currency of this.props.currencies) if (arr.indexOf(currency) === -1) arr.push(currency);
		} else {
			if (Array.isArray(this.state.currenciesSelected) && this.state.currenciesSelected.indexOf(this.state.currency) === -1)
				arr = [...this.state.currenciesSelected, this.state.currency];
			else arr = [this.state.currency];
		}
		await this.setState({ currenciesSelected: arr, currency: null });
		this.currencyComboRef.current.clear(); //clear selection
	};
	onCurrencyRemove = (e, currency) => {
		if (e) e.stopPropagation();
		if (this.state.currenciesSelected === null) return;
		let arr = this.state.currenciesSelected.filter((el) => el !== currency);
		if (arr.length === 0) arr = null;
		this.setState({ currenciesSelected: arr });
	};
	renderCurrenciesSelected = () => {
		const disabled = this.state.loading || this.props.disabled;
		if (!this.state.currenciesSelected) return <label>Please, select at least 1 currency</label>;
		return this.state.currenciesSelected.map((currency) => (
			<button key={currency} onClick={(e) => this.onCurrencyRemove(e, currency)} className="btn btnDefault m5_all" disabled={disabled}>
				{currency}
			</button>
		));
	};

	onContestCreate = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true });
		let msg = null;
		if (this.state.time_to.getTime() - this.state.time_from.getTime() <= 1000 * 60 || this.state.time_from.getTime() <= Date.now())
			msg = "Time range not correct";
		else if (parseFloat(this.state.amount) <= 0) msg = "Prize pool not set";
		else if (!Array.isArray(this.state.currenciesSelected) || this.state.currenciesSelected.length === 0) msg = "Contest currency not set";
		else if (this.state.gamesSelected === null) msg = "Contest games not set";
		else if (this.state.split === null) msg = "Prize pool split percentages not set";
		else if (this.state.name.trim() === "") msg = "Contest name not set";

		if (msg) {
			await this.setState({ loading: false });
			return Toast.error(msg);
		}
		const data = {
			db: this.props.vendor,
			name: this.state.name.trim() === "" ? null : this.state.name.trim(),
			description: this.state.description.trim() === "" ? null : this.state.description.trim(),
			amount: parseFloat(this.state.amount),
			currencies: this.state.currenciesSelected,
			time_from: this.state.time_from.getTime(),
			time_to: this.state.time_to.getTime(),
			games: this.state.gamesSelected,
			vendors: [this.props.vendor],
			percentages: this.state.split.split(","),
			image0: this.state.img0.trim() === "" ? null : this.state.img0.trim(),
			image1: this.state.img1.trim() === "" ? null : this.state.img1.trim(),
			image2: this.state.img2.trim() === "" ? null : this.state.img2.trim(),
			url: this.state.url.trim() === "" ? null : this.state.url.trim(),
		};
		contestCreate(data, async (res) => {
			if (res && !res.error) {
				await this.onClear();
				if (typeof this.props.onFinish === "function") this.props.onFinish();
			} else await this.setState({ loading: false });
		});
	};
	render() {
		const imp = <b className="important">*</b>;
		const disabled = this.state.loading || this.props.disabled;
		// const currencyOptions = !this.props.currencies ? null : this.props.currencies.map((el) => {
		// 	return { id: el, title: el }
		// });
		// if (currencyOptions) currencyOptions.unshift({ id: "all", title: "all" });
		let currencyOptions = null;
		if (this.state.currencies !== null) {
			let arr = [{ id: "all", title: "All" }];
			for (let currency of this.props.currencies) {
				if (this.state.currenciesSelected === null || this.state.currenciesSelected.indexOf(currency) === -1)
					arr.push({ id: currency, title: currency });
			}
			currencyOptions = arr;
		}

		let gameOptions = null;
		if (this.state.games !== null) {
			let arr = [{ id: "all", title: "All" }];
			for (let game in this.state.games) {
				if (this.state.gamesSelected === null || this.state.gamesSelected.indexOf(game) === -1)
					arr.push({ id: game, title: this.state.games[game] });
			}
			gameOptions = arr;
		}
		const hide =
			this.state.games !== null &&
			Array.isArray(this.state.gamesSelected) &&
			this.state.gamesSelected.length === Object.keys(this.state.games).length;
		const hideCurrency =
			this.props.currencies !== null &&
			Array.isArray(this.state.currenciesSelected) &&
			this.state.currenciesSelected.length === this.props.currencies.length;
		const splitOptions = [
			{ id: "100", title: "100 (1 winner)" },
			{ id: "67,33", title: "67, 33 (2 winners)" },
			{ id: "51,24,15", title: "51, 34, 15 (3 winners)" },
			{ id: "50,30,20", title: "50, 30, 20 (3 winners)" },
			{ id: "40,30,20,10", title: "40, 30, 20, 10 (4 winners)" },
			{ id: "12.50,11.67,10.83,10.00,9.17,8.33,7.50,6.67,5.83,5.00,4.17,3.33,2.50,1.67,0.83", title: "12.50, 11.67, 10.83... (15 winners)" },
			{ id: "30,13.5,5,4,3,2,2,2,2,2,2,2,2,2,2,2,2,2,2,1.5,1.5,1.5,1.5,1.5,1.5,1.5,1.5,1.5,1.5,1.5", title: "30, 13.5, 5... (30 winners)" },
			{
				id:
					"50,20,10,5.5,2.5,1.25,0.65,0.55,0.45,0.35,0.25,0.25,0.25,0.25,0.25,0.25,0.25,0.25,0.25,0.25,0.25,0.25,0.25,0.25,0.25,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2,0.2",
				title: "50, 20, 10... (50 winners)",
			},
			{
				id:
					"29.70297,14.85149,4.9505,3.9604,2.9703,1.9802,1.9802,1.9802,1.9802,1.9802,1.9802,1.9802,1.9802,1.9802,1.9802,1.9802,0.9901,0.9901,0.9901,0.9901,0.9901,0.9901,0.9901,0.9901,0.9901,0.9901,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505,0.49505",
				title: "29, 14, 4... (50 winners NY)",
			},
		];
		const minDate = this.state.time_from;
		const maxDate = new Date(new Date().setTime(minDate.getTime() + 30 * 24 * 60 * 60 * 1000));

		return (
			<div className="contestCreate">
				<div className="flex_h flex_wrap time">
					<div className="block">
						<label>Time from:{imp}</label>
						<DateTimePicker
							disableClock={true}
							minDate={new Date()}
							disabled={disabled}
							className="picker"
							calendarClassName="calendar"
							clockClassName="clock"
							onChange={this.onFromChange}
							value={this.state.time_from}
							required={true}
						/>
					</div>
					<div className="block">
						<label>Time to:{imp}</label>
						<DateTimePicker
							disableClock={true}
							minDate={minDate}
							maxDate={maxDate}
							disabled={disabled}
							className="picker"
							calendarClassName="calendar"
							clockClassName="clock"
							onChange={this.onToChange}
							value={this.state.time_to}
							required={true}
						/>
					</div>
				</div>
				<div className="flex_h flex_wrap time">
					<div className="block">
						<label>Prize pool:{imp}</label>
						<Input
							disabled={disabled}
							name="amount"
							onChange={this.onChange}
							value={this.state.amount}
							placeholder="amount"
							validation="amount"
						/>
					</div>
				</div>
				<div className="block">
					<label>Currency{imp}</label>
					<div className={`flex_h m10_b ${hideCurrency ? "hide" : ""}`}>
						<Combo
							style={{ width: "253px" }}
							ref={this.currencyComboRef}
							disabled={disabled}
							placeholder="Currency"
							onSelect={this.onCurrencyChange}
							selected={this.state.currency}
							options={currencyOptions}
						/>
						<button disabled={disabled} onClick={this.onCurrencyAdd} className="btn btnDefault add m10_l">
							<Icon icon={plus} />
						</button>
					</div>
					<div className="flex_h flex_wrap gamesSelected">{this.renderCurrenciesSelected()}</div>
				</div>

				<div className="block">
					<label>Split (%, winners){imp}</label>
					<Combo
						style={{ width: "300px" }}
						ref={this.splitComboRef}
						disabled={disabled}
						placeholder="Select split"
						onSelect={this.onSplitChange}
						selected={this.state.split}
						options={splitOptions}
					/>
				</div>
				<div className="block">
					<label>Games{imp}</label>
					<div className={`flex_h m10_b ${hide ? "hide" : ""}`}>
						<Combo
							style={{ width: "253px" }}
							ref={this.gameComboRef}
							disabled={disabled}
							placeholder="Choose games"
							onSelect={this.onGameChange}
							selected={this.state.game}
							options={gameOptions}
						/>
						<button disabled={disabled} onClick={this.onGameAdd} className="btn btnDefault add m10_l">
							<Icon icon={plus} />
						</button>
					</div>
					<div className="flex_h flex_wrap gamesSelected">{this.renderGamesSelected()}</div>
				</div>

				<div className="block">
					<label>Contest name:{imp}</label>
					<Input name="name" disabled={disabled} onChange={this.onChange} value={this.state.name} placeholder="Contest name" />
				</div>
				<div className="block">
					<label>Description (optional):</label>
					<Input
						type="textarea"
						disabled={disabled}
						name="description"
						onChange={this.onChange}
						value={this.state.description}
						placeholder="Description"
					/>
				</div>
				<div className="block">
					<label>Related images (optional):</label>
					<Input disabled={disabled} name="img0" onChange={this.onChange} value={this.state.img0} placeholder="https://..." />
				</div>
				<div className="block">
					<Input disabled={disabled} name="img1" onChange={this.onChange} value={this.state.img1} placeholder="https://..." />
				</div>
				<div className="block">
					<Input disabled={disabled} name="img2" onChange={this.onChange} value={this.state.img2} placeholder="https://..." />
				</div>
				<div className="block">
					<label>External URL (optional):</label>
					<Input disabled={disabled} name="url" onChange={this.onChange} value={this.state.url} placeholder="https://..." />
				</div>
				<div className="flex_h flex_c_c">
					<button onClick={this.onContestCreate} disabled={disabled} className="btn btnDefault">
						<Icon icon={trophy} />
						<label className="m5_l">Create contest</label>
					</button>
				</div>
			</div>
		);
	}
}
//--------------------------------------------------------------
class ContestList extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	onDelete = async (e, db, _id) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true });
		contestRemove({ _id, db }, () => {
			this.setState({ loading: false });
			this.props.onContestRemoved(_id);
		});
	};
	renderContests = (contests, hasDelete = false) => {
		return contests.map((el) => {
			return (
				<div className="contestItem" key={el._id}>
					<label className="caption">{el.name}</label>
					<div className="price">$ {el.amount}</div>
					<div
						className="img"
						style={{
							backgroundImage: `url(${
								el.image0 ? el.image0 : "https://res.cloudinary.com/dez0s9s32/image/upload/v1575026464/image-not-available.png"
							})`,
						}}
					></div>
					<div className="range">
						<div className="time from">{getTimeFromTimestamp(el.time_from * 1, {})}</div>
						<div className="time to">{getTimeFromTimestamp(el.time_to * 1, {})}</div>
					</div>
					{hasDelete && (
						<button
							disabled={this.props.disabled || this.state.loading}
							onClick={(e) => this.onDelete(e, this.props.vendor, el._id)}
							className="btn btnDefault m5_t w100"
						>
							Delete
						</button>
					)}
				</div>
			);
		});
	};
	render() {
		return (
			<div className="contestList">
				<label>Pending contests:</label>
				{this.props.pending.length > 0 ? (
					<div className="list active">{this.renderContests(this.props.pending, true)}</div>
				) : (
					<div className="m10_b">
						<label className="red1">No pending contests found</label>
					</div>
				)}
				<label>Active contests:</label>
				{this.props.contests.length > 0 ? (
					<div className="list active">{this.renderContests(this.props.contests)}</div>
				) : (
					<div className="m10_b">
						<label className="red1">No active contests found</label>
					</div>
				)}
				<label>Expired contests:</label>
				{this.props.expired.length > 0 ? (
					<div className="list expired">{this.renderContests(this.props.expired)}</div>
				) : (
					<div>
						<label className="red1">No expired contests found</label>
					</div>
				)}
			</div>
		);
	}
}
//--------------------------------------------------------------
class Contest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currencies: null,
			loading: false,
			vendor: null,
			pending: [],
			contests: [],
			expired: [],
			tab: 0,
		};
		this.dbComboRef = React.createRef();
	}
	componentDidMount = async () => {};
	onFinish = async () => {
		await this.setState({
			currencies: null,
			loading: false,
			vendor: null,
		});
		this.dbComboRef.current.clear();
	};
	onContestRemoved = async (_id) => {
		await this.setState({ pending: this.state.pending.filter((el) => el._id !== _id) });
	};
	onDbChange = (el) => {
		this.setState({ loading: true });
		getCurrencies({ vendor: el.id }, async (currencies) => {
			if (typeof currencies === "object" && !currencies.error) {
				await this.setState({ currencies, loading: false, vendor: el.id, tab: 0, pending: [], contests: [], expired: [] });
				contestList({ db: el.id }, async (res) => {
					if (res.success) this.setState({ pending: res.pending, contests: res.data, expired: res.expired });
				});
			} else this.setState({ loading: false });
		});
	};
	tabSelect = (e, i) => {
		if (e) e.stopPropagation();
		this.setState({ tab: i });
	};
	render() {
		const disabled = this.state.loading;
		const dbs = userDbs();
		const options = dbs.map((el) => {
			return { id: el, title: el };
		});
		return (
			<div className="contest">
				<TopBar />
				<div className="content">
					<div className="block dbs m10_b">
						<Combo
							style={{ width: "300px" }}
							ref={this.dbComboRef}
							disabled={disabled}
							placeholder="Choose DB"
							onSelect={this.onDbChange}
							selected={this.state.db}
							options={options}
						/>
					</div>
					{this.state.vendor && (
						<div className="tabs m10_b">
							<button onClick={(e) => this.tabSelect(e, 0)} className={`btn btnDefault ${this.state.tab === 0 ? "on" : ""} m10_r`}>
								Contests
							</button>
							<button onClick={(e) => this.tabSelect(e, 1)} className={`btn btnDefault ${this.state.tab === 1 ? "on" : ""} m10_r`}>
								Create new
							</button>
						</div>
					)}
					{this.state.tab === 0 && this.state.vendor && (
						<ContestList
							onContestRemoved={this.onContestRemoved}
							disabled={disabled || !this.state.vendor}
							vendor={this.state.vendor}
							currencies={this.state.currencies}
							pending={this.state.pending}
							contests={this.state.contests}
							expired={this.state.expired}
						/>
					)}
					{this.state.tab === 1 && this.state.vendor && (
						<ContestCreate
							onFinish={this.onFinish}
							disabled={disabled || !this.state.vendor}
							vendor={this.state.vendor}
							currencies={this.state.currencies}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default Contest;
