import { post } from "./axios"
// import { storageGet, storageSet, storageUnset } from "./storage"
import Toast from "./toast"

export const statsGeneral = (o, cb) => {
	const ready = (response) => {
		if (response.error) Toast.error(response.message ? response.message : "unexpected_error")
		if (typeof cb === "function") return cb(response);
	}

	post("/api/statsGeneral", o, ready);
}
export const statsTransactions = (o, cb) => {
	const ready = (response) => {
		if (response.error) Toast.error(response.message ? response.message : "unexpected_error")
		if (typeof cb === "function") return cb(response);
	}

	post("/api/statsTransactions", o, ready);
}