import { post, service } from "./axios";
import { storageGet, storageSet, storageUnset } from "./storage";
import { isValidMail, toErrorObject } from "./utills";
import Toast from "./toast";

let token = null;
let role = null;
let dbs = [];

export const userIsLogged = () => {
	token = storageGet("token", null);
	role = storageGet("role", null);
	return !!token;
};
export const userRole = () => {
	if (!role) role = storageGet("role", null);
	return role ? role : "user";
};
export const dbsAll = (cb) => {
	const ready = (response) => {
		// if (response.error) Toast.error(response.message ? response.message : "unexpected_error")
		if (typeof cb === "function") return cb(response);
	};
	post("/api/getDbsAll", {}, ready);
};
export const userDbs = () => {
	if (dbs.length === 0) dbs = storageGet("dbs", []);
	return !!dbs ? dbs : [];
};
export const getInfo = (cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error) Toast.error(response.message);
		else {
			const arr = Array.isArray(response.dbs) ? response.dbs : [];
			storageSet("dbs", arr);
			dbs = arr;
			if (typeof response.role === "string") {
				storageSet("role", response.role);
				role = response.role;
			}
		}
		if (typeof cb === "function") return cb(response);
	};
	post("/api/getInfo", {}, ready);
};
export const userLogin = (o, cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error) Toast.error(response.message);
		else {
			storageSet("token", response.token);
			storageSet("role", response.role);
			storageSet("dbs", Array.isArray(response.dbs) ? response.dbs : []);
			token = response.token;
			role = response.role;
			dbs = response.dbs;
			Toast.success(`Welcome, ${response.mail}`);
		}
		if (typeof cb === "function") return cb(response);
	};
	if (!isValidMail(o.mail)) {
		Toast.error("Invalid mail");
		if (typeof cb === "function") return cb({ error: true });
	} else if (o.password === "") {
		Toast.error("Password cannot be blank");
		if (typeof cb === "function") return cb({ error: true });
	}
	service.setHeaders({ recaptcha: o.recaptcha });
	post("/api/userLogin", o, ready);
};

export const userLogout = () => {
	storageUnset("token");
	token = null;
	storageUnset("role");
	role = null;
	storageUnset("dbs");
	dbs = null;
};
export const userActivate = (o, cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error || !response.token) Toast.error(response.message);
		else {
			storageSet("token", response.token);
			token = response.token;
			storageSet("role", response.role);
			role = response.role;
			storageSet("dbs", response.dbs);
			dbs = response.dbs;
			Toast.success("Account activated");
		}
		if (typeof cb === "function") return cb(response);
	};
	const { password1, password2 } = o;

	if (password1 !== password2) {
		Toast.error("Passwords do not match");
		if (typeof cb === "function") return cb({ error: true });
	}
	if (password1.length < 1) {
		Toast.error("Invalid password");
		if (typeof cb === "function") return cb({ error: true });
	}
	post("/api/userActivate", { password: o.password1, uuid: o.uuid }, ready);
};
export const userPasswordChange = (o, cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error || !response.token) Toast.error(response.message);
		else {
			storageSet("token", response.token);
			token = response.token;
			Toast.success("Password changed");
		}
		if (typeof cb === "function") return cb(response);
	};
	const { passwordOld, password1, password2 } = o;

	if (password1 !== password2) {
		Toast.error("Passwords do not match");
		if (typeof cb === "function") return cb({ error: true });
	}
	if (password1.length < 1) {
		Toast.error("Invalid password");
		if (typeof cb === "function") return cb({ error: true });
	}
	post("/api/userPasswordChange", { passwordOld, password: password1 }, ready);
};
export const userRecover = (o, cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error) Toast.error(response.message);
		else Toast.success("Recovery link was sent to the specified mail");
		if (typeof cb === "function") return cb(response);
	};
	if (!isValidMail(o.mail)) {
		Toast.error("Invalid mail");
		if (typeof cb === "function") return cb({ error: true });
	}
	post("/api/userRecover", o, ready);
};
export const userSendInvite = (o, cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error) Toast.error(response.message);
		else {
			Toast.success("Invite has been sent");
		}
		if (typeof cb === "function") return cb(response);
	};
	if (!isValidMail(o.mail)) {
		Toast.error("Invalid mail");
		if (typeof cb === "function") return cb({ error: true });
	}
	post("/api/userSendInvite", o, ready);
};
export const getUsers = (cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error) Toast.error(response.message);
		if (typeof cb === "function") cb(response);
	};
	post("/api/getUsers", {}, ready);
};
export const userDbAssign = (o, cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error) Toast.error(response.message);
		else {
			if (dbs.indexOf(o.db) === -1) {
				dbs.push(o.db);
				storageSet("dbs", dbs);
			}
			Toast.success("DB assigned");
		}
		if (typeof cb === "function") return cb(response);
	};
	if (!isValidMail(o.mail)) {
		Toast.error("Invalid mail");
		if (typeof cb === "function") return cb({ error: true });
	}
	if (typeof o.db !== "string") {
		Toast.error("Invalid db");
		return cb({ error: true });
	}
	post("/api/assignDb", o, ready);
};
export const userDbUnassign = (o, cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error) Toast.error(response.message);
		else {
			if (dbs.indexOf(o.db) !== -1) {
				dbs = dbs.filter((el) => el !== o.db);
				storageSet("dbs", dbs);
			}
			Toast.success("DB unassigned");
		}
		if (typeof cb === "function") return cb(response);
	};
	if (!isValidMail(o.mail)) {
		Toast.error("Invalid mail");
		if (typeof cb === "function") return cb({ error: true });
	}
	if (typeof o.db !== "string") {
		Toast.error("Invalid db");
		return cb({ error: true });
	}
	post("/api/unassignDb", o, ready);
};
export const setRole = (o, cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error) Toast.error(response.message);
		else {
			Toast.success("Role assigned");
		}
		if (typeof cb === "function") return cb(response);
	};
	if (!isValidMail(o.mail)) {
		Toast.error("Invalid mail");
		if (typeof cb === "function") return cb({ error: true });
	}
	if (!["user", "admin"].includes(o.role)) {
		Toast.error(`Invalid role: ${o.role}`);
		return cb({ error: true });
	}
	post("/api/setRole", o, ready);
};
export const getGames = (o, cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error) Toast.error(response.message);
		if (typeof cb === "function") return cb(response);
	};
	post("/api/getGames", o, ready);
};
export const getCurrencies = (o, cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error) Toast.error(response.message);
		if (typeof cb === "function") return cb(response);
	};
	post("/api/getCurrencies", o, ready);
};
