import { post } from "./axios"
// import { storageGet, storageSet, storageUnset } from "./storage"
import { toErrorObject } from "./utills"
import Toast from "./toast"

export const contestCreate = (o, cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error) Toast.error(response.message)
		if (response.success) Toast.success("Contest successfully created")
		if (typeof cb === "function") return cb(response);
	}
	post("/api/contestCreate", o, ready);
}

export const contestList = (o, cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error) Toast.error(response.message)
		if (typeof cb === "function") return cb(response);
	}
	post("/api/contestList", o, ready);
}

export const contestRemove = (o, cb) => {
	const ready = (response) => {
		response = toErrorObject(response);
		if (response.error) Toast.error(response.message)
		if (response.success) Toast.success("Contest successfully removed")
		if (typeof cb === "function") return cb(response);
	}
	post("/api/contestRemove", o, ready);
}