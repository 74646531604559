import React, { Component } from "react";
//classes:
import history from "../../classes/history";
import Combo from "../../components/combo/Combo";
import { userDbs } from "../../classes/user";
import { post } from "../../classes/axios";
//components:
import Input from "../../components/input/Input";
//containers:
import TopBar from "../../containers/topBar/TopBar";
//other:
// import Decimal from "decimal.js";
//icons:
import Icon from "react-icons-kit";
import { send } from "react-icons-kit/fa/send";
import toast from "../../classes/toast";

const templates = {
	add_coin: { command: "addCoin", coin: "btc", min: 0.00000001, max: 5, afterDot: 8, highRollAmount: 4 },
	remove_coin: { command: "removeCoin", coin: "btc" },
	recalculate_daily_stats: { command: "recalculateDailyStats", date: "2019-2-20" },
	read_contest: { command: "readContest" },
	set_turn_off_time: { command: "setTurnOffTime", turnOffAfterMS: 5000 },
	read_config_from_db: { command: "readConfigFromDB" },
};
const templatesArr = [];
for (let el in templates) templatesArr.push({ id: el, title: el });
Object.freeze(templates);

class Password extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			command: "",
			commandResponse: "",
			db: "",
		};
	}
	onDbChange = (el) => {
		this.setState({ db: el });
	};
	onInputChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};
	onCommandSelect = ({ id }) => {
		this.setState({ command: JSON.stringify(templates[id]) });
	};
	onCommandSend = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true });
		try {
			await post("/api/command", { db: this.state.db.id, data: JSON.parse(this.state.command) }, (resp) => {
				this.setState({ commandResponse: JSON.stringify(resp), loading: false });
			});
		} catch (err) {
			toast.error("INVALID JSON FORMAT");
			this.setState({ loading: false });
		}
	};
	renderCommands() {
		const disabled = this.state.loading;
		const dbs = userDbs();
		const options = dbs.map((el) => {
			return { id: el, title: el };
		});
		return (
			<div className="command">
				<TopBar />
				<div className="content flex_v">
					<div className="block dbs m10_b">
						<Combo
							style={{ width: "100%" }}
							disabled={disabled}
							placeholder="Choose DB"
							onSelect={this.onDbChange}
							selected={this.state.db}
							options={options}
						/>
					</div>
					<Combo
						style={{ width: "100%" }}
						ref={this.currencyComboRef}
						disabled={disabled}
						placeholder="Commands"
						onSelect={this.onCommandSelect}
						// selected={this.state.currency}
						options={templatesArr}
					/>
					<label>Command content:</label>
					<Input name="command" type="textarea" allowenter="true" onChange={this.onInputChange} value={this.state.command} />
					<label>Response:</label>
					<Input name="commandResponse" type="textarea" readOnly="readonly" value={this.state.commandResponse} />
					<div className="buttons">
						<button disabled={disabled} onClick={this.onCommandSend} className="btn btnDefault send">
							<Icon icon={send} />
							<b>Submit</b>
						</button>
					</div>
				</div>
			</div>
		);
	}
	render() {
		return this.renderCommands();
	}
}

export default Password;
