const prefix = "pand_adm:";

export const storageSet = (key, value) => localStorage.setItem(`${prefix}${key}`, JSON.stringify({ key: value }));

const get = key => {
	const item = localStorage.getItem(`${prefix}${key}`);
	let result = null;
	try {
		if (item) result = JSON.parse(item).key;
	} catch (err) {
		result = item === undefined ? null : item;
	}
	return result;
};

export const storageGet = (key, defaultValue = null, checkF, args) => {
	const v = get(key);
	if (v === null) {
		return defaultValue;
	} else {
		if (typeof checkF === "function") {
			if (checkF(v, args)) return v;
			else return defaultValue;
		} else {
			return v;
		}
	}
};

export const storageUnset = key => localStorage.removeItem(`${prefix}${key}`);

export const storageClear = () => localStorage.clear();