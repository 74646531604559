import React from "react";
//other:
import { Decimal } from "decimal.js";

const validate = (data, patterns) => {
	let test = true;
	let replaceComma = false;
	patterns.forEach((pattern) => {
		switch (pattern) {
			case "amount":
				if (!/^[0-9]{0,}?(\.|,)?[0-9]{0,8}$/g.test(data)) test = false;
				if (data.charAt(0) === "." || data.charAt(0) === ",") test = false; //.234
				if (data.indexOf(".") === -1 && data.indexOf(",") === -1 && data.startsWith("0") && data.length > 1) test = false; //0123
				if ((data.indexOf(".") > 1 || data.indexOf(",") > 1) && data.startsWith("0")) test = false; //01.
				replaceComma = true;
				break;
			case "chance":
				if (!/^[0-9]{0,}?(\.|,)?[0-9]{0,3}$/g.test(data)) test = false;
				if (data.length > 2 && data.indexOf(".") === -1 && data.indexOf(",") === -1) test = false;
				if (data === "0.00" || data === "0,00") test = false;
				replaceComma = true;
				break;
			case "coinName":
				if (!/^[a-zA-Z\s]{0,20}$/g.test(data) && data !== "") test = false;
				break;
			case "searchKeyword":
				if (!/^[a-zA-Zа-яА-Я0-9]{0,1}[a-zA-Zа-яА-Я0-9]{0,50}$/g.test(data) && data !== "") test = false;
				break;
			case "integer":
				test = data === "" || (parseInt(data) + "" === data && !isNaN(data));
				break;
			case "payout":
				if (!/^[0-9]{0,}?(\.|,)?[0-9]{0,3}$/g.test(data)) test = false;
				if (data.indexOf(".") > 4 || data.indexOf(",") > 4) test = false;
				if (data.length > 4 && data.indexOf(".") === -1 && data.indexOf(",") === -1) test = false;
				replaceComma = true;
				break;
			case "password":
				if (!/^[a-zA-Z0-9!@#$%^&*()_+\-=]{0,12}$/gi.test(data)) test = false;
				break;
			case "percent":
				if (!/^-?[0-9]{0,}?(\.|,)?[0-9]{0,2}$/g.test(data)) test = false;
				if (data.charAt(0) === "." || data.charAt(0) === ",") test = false; //.234
				if (data.indexOf(".") === -1 && data.indexOf(",") === -1 && data.startsWith("0") && data.length > 1) test = false; //0123
				if ((data.indexOf(".") > 1 || data.indexOf(",") > 1) && data.startsWith("0")) test = false; //01.
				if (data.indexOf("-") > 0 || data.indexOf("-.") !== -1 || data.indexOf("-,") !== -1 || data.indexOf("-00") !== -1) test = false; //-, 2342- , -.
				if (data.indexOf("-0") !== -1 && data.indexOf(".") !== 2 && data.indexOf(",") !== 2 && data.length > 2) test = false; //-01
				replaceComma = true;
				break;
			case "negativeAmount":
				if (!/^-?[0-9]{0,}?(\.|,)?[0-9]{0,8}$/g.test(data)) test = false;
				if (data.charAt(0) === "." || data.charAt(0) === ",") test = false; //.234
				if (data.indexOf(".") === -1 && data.indexOf(",") === -1 && data.startsWith("0") && data.length > 1) test = false; //0123
				if ((data.indexOf(".") > 1 || data.indexOf(",") > 1) && data.startsWith("0")) test = false; //01.
				if (data.indexOf("-") > 0 || data.indexOf("-.") !== -1 || data.indexOf("-,") !== -1 || data.indexOf("-00") !== -1) test = false; //-, 2342- , -.
				if (data.indexOf("-0") !== -1 && data.indexOf(".") !== 2 && data.indexOf(",") !== 2 && data.length > 2) test = false; //-01
				replaceComma = true;
				break;
			case "rollValue":
				if (!/^[0-9]{0,}?(\.|,)?[0-9]{0,2}$/g.test(data)) test = false;
				if (data.indexOf(".") > 2 || data.indexOf(",") > 2) test = false;
				if (data.length > 2 && data.indexOf(".") === -1 && data.indexOf(",") === -1) test = false;
				if (data === "0.00") test = false;
				replaceComma = true;
				break;
			case "short":
				if (!/^[A-Z]{0,5}$/g.test(data) && data !== "") test = false;
				break;
			case "roomName":
				if (!/^[a-zA-Z]{0,1}[a-zA-Z0-9\-_]{0,4}$/gi.test(data) && data !== "") test = false;
				break;
			case "2fa":
				if (!/^[0-9]{0,6}$/gi.test(data) && data !== "") test = false;
				break;
			case "userName":
				if (!/^[a-zA-Z]{0,1}[a-zA-Z0-9\-_]{0,11}$/gi.test(data) && data !== "") test = false;
				break;
			default:
		}
	});
	return { test, replaceComma };
};

const onEventValidation = (e, validation, f) => {
	if (validation) {
		const patterns = validation.indexOf("|") > -1 ? validation.split("|") : [validation];
		const result = validate(e.target.value, patterns);
		if (result.test) {
			if (result.replaceComma) e.target.value = e.target.value.replace(",", ".");
			if (f) f(e);
			else return true;
		} else {
			return false;
		}
	} else {
		if (f) f(e);
	}
};
const onBlurValidation = (e, f, min, max, toFixed) => {
	//change value
	if (min !== null || max !== null || toFixed !== null) {
		const dValue = new Decimal(e.target.value === "" || e.target.value === "-" ? 0 : e.target.value);
		if (toFixed !== null) e.target.value = dValue.toFixed(toFixed);
		if (min !== null && dValue.lessThan(min)) {
			e.target.value = toFixed === null ? min : new Decimal(min).toFixed(toFixed);
		} else if (max !== null && dValue.greaterThan(max)) {
			e.target.value = toFixed === null ? max : new Decimal(max).toFixed(toFixed);
		} else {
			if (toFixed !== null) e.target.value = dValue.toFixed(toFixed);
		}
	}
	if (f) f(e);
};
const onWheelFunc = (e, f) => {
	if (e.target !== document.activeElement) return; //scroll only if focused
	if (f) {
		e.preventDefault();
		e.stopPropagation();
		f(e.deltaY);
	}
};
const disableNewLine = (e, onEnter) => {
	if (e.keyCode === 13 && !e.shiftKey) {
		e.preventDefault();
		if (onEnter) onEnter(e);
		return false;
	}
};

const Input = ({
	refProp = null,
	type = "text",
	value,
	placeholder = "",
	disabled,
	readOnly,
	onChange = null,
	onBlur = null,
	onEnter = ()=>{},
	onWheel = null,
	validation,
	maxLength = 1000000,
	min = null,
	max = null,
	toFixed = null,
	name,
	className,
	allowenter = "false",
	style,
}) => {
	const props = {
		"data-lpignore": true,
		autoComplete: "off",
		style: style,
		className: className,
		value: value,
		name: name ? name : `${Date.now()}${Math.random()}`,
		maxLength: maxLength,
		disabled: disabled,
		type: type,
		placeholder: placeholder,
		readOnly: readOnly,
		allowenter: allowenter,
		ref: refProp,
		onWheel: (e) => onWheelFunc(e, onWheel),
		onChange: (e) => onEventValidation(e, validation, onChange),
		onBlur: (e) => onBlurValidation(e, onBlur, min, max, toFixed),
	};
	if (props.type === "textarea") {
		delete props.type;
		return <textarea {...props} onKeyDown={(e) => (allowenter === "true" ? onEnter(e) : disableNewLine(e, onEnter))} />;
	} else {
		return <input {...props} onKeyDown={(e) => (allowenter === "true" ? onEnter(e) : disableNewLine(e, onEnter))} />;
	}
};

export default Input;
