import React, { Component } from "react";
//containers:
import TopBar from "../topBar/TopBar";
//classes:

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<div className="main">
				<TopBar />
			</div>
		);
	}
}

export default Main;
