import React, { Component } from "react";

//other:
import { Link } from "react-router-dom"

//classes:
import { userLogout, userRole } from "../../classes/user"
import history from "../../classes/history"

//icons:
import Icon from "react-icons-kit";
import { bars } from 'react-icons-kit/fa/bars'
import { user } from 'react-icons-kit/fa/user'
import { chevronUp } from 'react-icons-kit/fa/chevronUp'
// import { share as invites } from 'react-icons-kit/fa/share'
import { group as users } from 'react-icons-kit/fa/group'
import { barChart as stats } from 'react-icons-kit/fa/barChart'
import { trophy as contests } from 'react-icons-kit/icomoon/trophy'
import { cogs as settings } from 'react-icons-kit/fa/cogs'

class TopBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			menuVisible: false
		}
	}

	showMenu = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ menuVisible: !this.state.menuVisible })
	}

	onLogoutClick = async (e) => {
		if (e) e.stopPropagation();
		await this.setState({ loading: true });
		userLogout();
		this.setState({ loading: false, menuVisible: false }, () => {
			history.push(`./login?t=${Date.now()}`);
		});
	}

	renderMenu = () => {
		const role = userRole();
		return <div className={`topMenu ${this.state.menuVisible ? "visible" : "hidden"}`}>
			<div className="content">
				{role === "admin" && <Link onClick={this.showMenu} to={`./users?t=${Date.now()}`}>
					<div className="flex_h flex_c_c p10_all menuItem">
						<Icon icon={users} />
						<label className="m20_l">Users</label>
					</div>
				</Link>}
				<Link onClick={this.showMenu} to={`./stats?t=${Date.now()}`}>
					<div className="flex_h flex_c_c p10_all menuItem">
						<Icon icon={stats} />
						<label className="m20_l">Stats</label>
					</div>
				</Link>
				{role === "admin" && <Link onClick={this.showMenu} to={`./transactions?t=${Date.now()}`}>
					<div className="flex_h flex_c_c p10_all menuItem">
						<Icon icon={stats} />
						<label className="m20_l">Transactions</label>
					</div>
				</Link>}
				<Link onClick={this.showMenu} to={`./contest?t=${Date.now()}`}>
					<div className="flex_h flex_c_c p10_all menuItem">
						<Icon icon={contests} />
						<label className="m20_l">Contests</label>
					</div>
				</Link>
				<Link onClick={this.showMenu} to={`./settings?t=${Date.now()}`}>
					<div className="flex_h flex_c_c p10_all menuItem">
						<Icon icon={settings} />
						<label className="m20_l">Settings</label>
					</div>
				</Link>
			</div>
			<button className="btn btnDefault btnHide scale08" onClick={this.showMenu}><Icon icon={chevronUp} /></button>
		</div>
	}
	render() {
		return (<div className="topBar flex_h flex_c_b">
			<button onClick={this.showMenu} className="btn btnDefault flex_h flex_c_c">
				<Icon icon={bars} />
				<label className="m5_l">Menu</label>
			</button>
			<div className="content flex1"></div>
			<button disabled={this.state.loading} className="btn btnDefault flex_h flex_c_c" onClick={this.onLogoutClick}>
				<Icon icon={user} />
				<label className="m5_l">Logout</label>
			</button>
			<div className="menuWrapper">
				{this.renderMenu()}
			</div>
		</div>);
	}
}

export default TopBar;